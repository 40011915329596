
// @ts-nocheck
import { Form, SearchableSelect } from "@num/component-library";
import axios, { AxiosError, AxiosResponse } from "axios";
import _debounce from "lodash/debounce";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import { Result } from "@/types/index";

import { APPS_API } from "@/app.config";

interface Data {
  companyNameResult: string;
  domainName: string;
  result: string | null;
}

interface Option {
  domain: string;
  result: Result;
}

export default Vue.extend({
  name: "CompanySearch",
  components: {
    Form,
    SearchableSelect,
  },
  data(): Data {
    return {
      axiosCancelTokenSource: null,
      companyNameResult: "" as string,
      companyResultsArray: [],
      domainName: "" as string,
      keywordSearchErrored: false,
      isSearching: false,
      result: null as string | null,
      searchableSelectInput: "",
    };
  },
  computed: {
    ...mapGetters(["countryCode"]),
    ...mapState(["jsonCompanyResults"]),
    domainWithoutCruft() {
      if (!this.searchIsDomain) return null;
      const url = this.searchableSelectInput.startsWith("http")
        ? this.searchableSelectInput
        : `http://${this.searchableSelectInput}`;
      try {
        return new URL(url)?.hostname?.replace("www.", "");
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    noResultsMessage() {
      if (!this.searchableSelectInput)
        return "Enter a company name to search for";
      else if (this.isSearching)
        return `Finding companies with that ${
          this.searchIsDomain ? "domain" : "name"
        }...`;
      else if (this.keywordSearchErrored)
        return "There was an error searching for companies, please try again and get in touch at contact@numserver.com if this error persists.";
      else if (this.searchIsDomain)
        return "No company results found for that domain name";
      return "No companies found, try refining your search or entering a domain name instead";
    },
    searchIsDomain() {
      const inputWithoutHttp = this.searchableSelectInput.replace(
        /^(http:\/\/)/,
        "",
      );
      const inputWithoutHttps = inputWithoutHttp.replace(/^(https:\/\/)/, "");
      const inputWithoutWww = inputWithoutHttps.replace(/^(www\.)/, "");
      return /^(([^.\s\f\t\r\b\\]+\.)*([^!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~\s\f\t\r\b\\]+\.)([^!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~\s\f\t\r\b\\]+))[/]?\.?$/.test(
        inputWithoutWww,
      );
    },
  },
  watch: {
    companyNameResult() {
      this.submitCompanyNameForm();
    },
  },
  methods: {
    companyKeywordSearch(input) {
      this.axiosCancelTokenSource = axios.CancelToken.source();

      axios
        .post(
          `${APPS_API}/keyword/search`,
          {
            keywords: input,
            country: this.countryCode.toUpperCase(),
            language: "en",
            pageSize: 5,
          },
          {
            cancelToken: this.axiosCancelTokenSource.token,
          },
        )
        .then(async (successfulResponse: AxiosResponse) => {
          this.companyResultsArray = successfulResponse.data;
          this.keywordSearchErrored = false;
          this.isSearching = false;

          return successfulResponse;
        })
        .catch((error: AxiosError) => {
          console.error(error);

          if (!axios.isCancel(error)) {
            this.isSearching = false;
            this.keywordSearchErrored = true;
          }

          return error.response;
        });
    },
    debouncedCompanyKeywordSearch: _debounce(function (input) {
      this.companyKeywordSearch(input);
    }, 200),
    onCompanyNameInput(input) {
      this.searchableSelectInput = input;
      this.isSearching = true;
      this.companyResultsArray = [];
      this.axiosCancelTokenSource?.cancel();

      if (input) {
        this.debouncedCompanyKeywordSearch(this.domainWithoutCruft || input);
      } else {
        this.isSearching = false;
      }
    },
    onEnterKeypress() {
      this.companyKeywordSearch(
        this.domainWithoutCruft || this.searchableSelectInput,
      );
    },
    optionImageUrl(o: Option) {
      return o.images?.[0]?.variants?.[0]?.url;
    },
    optionImageAlt(o: Option) {
      return `${o.name || "Company"} logo`;
    },
    optionOutput(o: Option) {
      if (!o) return;
      const optionName = o.name;
      const optionSlogan = o.slogan;
      return `${optionName}${optionSlogan ? ` — ${optionSlogan}` : ""}`;
    },
    submitCompanyNameForm() {
      this.$router.push({
        name: "company",
        params: {
          companyDomain: this.companyNameResult.toLowerCase(),
        },
      });
    },
    submitDomainNameForm() {
      this.$router.push({
        name: "company",
        params: {
          companyDomain: this.domainName.toLowerCase(),
        },
      });
    },
  },
});
